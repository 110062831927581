
import { Delegates, Services } from "@/api";
import { Delegate, EmailVerificationStatuses, Gender } from "@/api/types";
import Card from "@/components/common/Card.vue";
import ChangePasswordModal from "@/components/common/ChangePasswordModal.vue";
import PageTitle from "@/components/common/PageTitle.vue";
import PhoneInput from "@/components/common/PhoneInput.vue";
import Table from "@/components/common/Table.vue";
import CompaniesAssign from "@/components/companies/CompaniesAssign.vue";
import DelegateDocuments from "@/components/delegates/DelegateDocuments.vue";
import { PhoneCodes } from "@/mixins/countryCodes.mixin";
import moment from "moment";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {
    CompaniesAssign,
    PageTitle,
    Table,
    Card,
    ValidationObserver,
    ValidationProvider,
    ChangePasswordModal,
    DelegateDocuments,
    PhoneInput
  },
  mixins: [PhoneCodes]
})
export default class DelegatesEditPage extends Vue {
  delegate: Delegate = {} as Delegate;
  companyId = "0";
  genderId = 1;
  tab = 0;

  genders: Array<Gender> = [];
  titles: Array<string> = [];
  alternativeTypes: Array<string> = [];
  languageCodes: Array<string> = [];
  provinceCodes: Array<string> = [];
  citizenResidentStatusCodes: Array<string> = [];
  licenseCodes: Array<string> = [];
  equityCodes: Array<string> = [];
  disabilityCodes: Array<string> = [];
  nationalityCodes: Array<string> = [];

  menu = false;
  menu1 = false;
  postalSameAsHome = false;
  headersTitle = [
    { icon: "mdi-calendar-month", header: "Delegate Profile Editor" }
  ];
  emailStatus: EmailVerificationStatuses | null = null;
  gettingStatus = false;
  sendingEmail = false;

  get buttonsArray() {
    const buttons = [
      {
        text: "Back",
        icon: "mdi-chevron-left",
        color: "white",
        action: "back"
      },
      {
        text: "Delete",
        icon: "mdi-delete",
        color: "gray",
        action: "delete"
      },
      {
        text: "Change password",
        icon: "mdi-lock-reset",
        color: "gray",
        action: "changePassword"
      }
    ];

    if (this.tab !== 1) {
      buttons.push({
        text: "Save",
        icon: "mdi-pencil-box-outline",
        color: "dark",
        action: "save"
      });
    }
    return buttons;
  }

  get delegateId() {
    return this.$route.params.id;
  }
  get dateFormatedBirthday() {
    return moment(this.delegate.birthday).format("MM/DD/YYYY");
  }
  get dateFormatedlicenceExpiry() {
    return this.delegate.licenceExpiryDate
      ? moment(this.delegate.licenceExpiryDate).format("MM/DD/YYYY")
      : "";
  }

  @Watch("postalSameAsHome")
  postalSameAsHomeHandler(val: boolean) {
    if (val) {
      this.delegate.postalAddress = this.delegate.homeAddress;
      this.delegate.postalAddressPostalCode = this.delegate.homeAddressPostalCode;
    } else {
      this.delegate.postalAddress = "";
      this.delegate.postalAddressPostalCode = "";
    }
  }

  async created() {
    try {
      const [delegate, servise]: any = await Promise.all([
        Delegates.info(this.delegateId),
        Services.list()
      ]);
      this.delegate = delegate;
      this.genders = servise.genders;
      this.titles = servise.titles;
      this.alternativeTypes = servise.alternativeTypes;
      this.languageCodes = servise.homeLanguageCodes;
      this.provinceCodes = servise.provinceCode;
      this.citizenResidentStatusCodes = servise.citizenResidentStatusCode;
      this.licenseCodes = servise.licenseCode;
      this.equityCodes = servise.equityCode;
      this.disabilityCodes = servise.disabilityCode;
      this.nationalityCodes = servise.nationalityCode;

      this.delegate.textNote = this.delegate.textNote
        ? this.delegate.textNote
        : "";
      this.companyId = delegate.company.id;
      this.delegate.genderId = delegate.gender.id;
      this.delegate.birthday = moment(
        this.delegate.birthday,
        "DD-MM-YYYY"
      ).format("YYYY-MM-DD");
      this.delegate.licenceExpiryDate = this.delegate.licenceExpiryDate
        ? moment(this.delegate.licenceExpiryDate, "DD-MM-YYYY").format(
            "YYYY-MM-DD"
          )
        : "";

      if (
        this.delegate.homeAddress &&
        this.delegate.postalAddress &&
        this.delegate.homeAddress === this.delegate.homeAddressPostalCode &&
        this.delegate.postalAddress === this.delegate.postalAddressPostalCode
      )
        this.postalSameAsHome = true;

      if (this.delegate.vaultAccess) {
        this.getStatus(this.delegateId);
      }
    } catch (e) {
      const err = e as any;
      await this.$error(err.errors);
    }
  }
  setDateOfBirth() {
    let splitedId: any = this.delegate.passport;
    if (splitedId.length >= 6 && !isNaN(+splitedId)) {
      splitedId = splitedId.match(/.{1,2}/g);
      const year =
        splitedId[0] > 15 ? "19" + splitedId[0] : "20" + splitedId[0];
      const month = splitedId[1] > 12 ? 12 : splitedId[1];
      const day = splitedId[2] > 31 ? 31 : splitedId[2];

      const preFormattedDate = `${year}-${month}-${day}`;

      this.delegate.birthday = preFormattedDate;
    }
  }
  async save() {
    try {
      const data = JSON.parse(JSON.stringify(this.delegate));

      data.birthday = moment(this.delegate.birthday).format("DD-MM-YYYY");
      data.companyId = this.companyId;
      data.genderId = this.genderId;

      if (data.textNote.length === 0 || data.textNote === null)
        data.textNote = "";

      data.licenceExpiryDate = moment(
        data.licenceExpiryDate,
        "YYYY-MM-DD"
      ).format("DD-MM-YYYY");

      await Delegates.update(this.delegateId, data);
      await this.$success(
        "<strong>Success!</strong> Delegate has been updated!"
      );

      this.tab += 1;
      // if (this.$route.name === "DelegatesEdit") {
      //   this.$router.push({ name: "DelegatesList" });
      // }
    } catch (e) {
      const err = e as any;
      await this.$error(err.errors || { err: "Something went wrong" });
      (this.$refs.delegateForm as Vue & {
        setErrors: (errors: any) => void;
      }).setErrors(err.errors);
    }
  }
  async onSave() {
    (this.$refs.form as Vue & {
      click: () => void;
    }).click();
  }
  async onDelete() {
    const res = await this.$dialog.error({
      text: "Do you really want to delete item?",
      title: "Warning",
      persistent: false,
      actions: {
        No: { text: "No" },
        Yes: {
          color: "red",
          text: "Yes I do"
        }
      }
    });
    if (res === "Yes") {
      await Delegates.remove(this.delegateId);
      await this.onGoBack();
    }
  }
  async onGoBack() {
    await this.$router.push({ name: "DelegatesList" });
  }
  changePassword() {
    this.$modal.show("change-paswword-modal");
  }
  formatDate(date: string | undefined): string {
    if (date === undefined) {
      return "";
    }
    const [day, month, year] = date.split("-");
    return `${month}/${day}/${year}`;
  }
  homePostalCodeHandler(val: string) {
    if (this.postalSameAsHome) {
      this.delegate.postalAddressPostalCode = val;
    }
  }
  homeAddressHandler(val: string) {
    if (this.postalSameAsHome) {
      this.delegate.postalAddress = val;
    }
  }
  async getStatus(id: string) {
    if (id) {
      this.gettingStatus = true;
      try {
        const { status } = await Delegates.getVaultEmailStatus(id);

        this.emailStatus = status;
      } catch (e) {
        const err = e as any;
        this.$error(
          err?.errors || { err: err.result?.message || "Cant get status" }
        );
      } finally {
        this.gettingStatus = false;
      }
    }
  }
  async resentEmail(id: string) {
    if (!id) return;
    try {
      this.sendingEmail = true;
      await Delegates.resendVaultVerificationEmail(id);
      this.$success("Verification email sent successfully");
    } catch (e) {
      const err = e as any;
      this.$error(
        err?.errors || { err: err.result?.message || "Cant send email" }
      );
    } finally {
      this.sendingEmail = false;
    }
  }
}
