
import { Companies, Delegates } from "@/api";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Component, Prop, Vue } from "vue-property-decorator";

export type ChangebleType = "cr" | "delegate";

@Component({
  components: { ValidationObserver, ValidationProvider }
})
export default class ChangePasswordModal extends Vue {
  @Prop()
  changebleId!: string;
  @Prop()
  changeble!: ChangebleType;

  password = "";
  confirmPassword = "";

  close() {
    this.resetForm();
    this.$modal.hide("change-paswword-modal");
  }

  resetForm() {
    this.password = "";
    this.confirmPassword = "";
  }

  async changePassword(): Promise<void> {
    try {
      if (this.changeble === "delegate") {
        await Delegates.changePassword(
          this.changebleId,
          this.password,
          this.confirmPassword
        );
      }
      if (this.changeble === "cr") {
        await Companies.changePassword(
          this.changebleId,
          this.password,
          this.confirmPassword
        );
      }
      await this.$success("Password has been changed successfully");
      this.resetForm();
      this.close();
    } catch (e) {
      const { errors } = e as any;
      (this.$refs.changePasswordForm as Vue & {
        setErrors: (errors: any) => void;
      }).setErrors(errors);
      await this.$error(errors);
    }
  }
}
